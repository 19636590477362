// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-common-banner-js": () => import("./../src/pages/common/Banner.js" /* webpackChunkName: "component---src-pages-common-banner-js" */),
  "component---src-pages-common-tab-panel-js": () => import("./../src/pages/common/TabPanel.js" /* webpackChunkName: "component---src-pages-common-tab-panel-js" */),
  "component---src-pages-contato-contact-form-js": () => import("./../src/pages/contato/ContactForm.js" /* webpackChunkName: "component---src-pages-contato-contact-form-js" */),
  "component---src-pages-contato-js": () => import("./../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-contato-side-bar-js": () => import("./../src/pages/contato/SideBar.js" /* webpackChunkName: "component---src-pages-contato-side-bar-js" */),
  "component---src-pages-homepage-area-cards-js": () => import("./../src/pages/homepage/AreaCards.js" /* webpackChunkName: "component---src-pages-homepage-area-cards-js" */),
  "component---src-pages-homepage-brand-cards-js": () => import("./../src/pages/homepage/BrandCards.js" /* webpackChunkName: "component---src-pages-homepage-brand-cards-js" */),
  "component---src-pages-homepage-first-slider-js": () => import("./../src/pages/homepage/FirstSlider.js" /* webpackChunkName: "component---src-pages-homepage-first-slider-js" */),
  "component---src-pages-homepage-main-content-js": () => import("./../src/pages/homepage/MainContent.js" /* webpackChunkName: "component---src-pages-homepage-main-content-js" */),
  "component---src-pages-homepage-map-js": () => import("./../src/pages/homepage/Map.js" /* webpackChunkName: "component---src-pages-homepage-map-js" */),
  "component---src-pages-homepage-second-slider-js": () => import("./../src/pages/homepage/SecondSlider.js" /* webpackChunkName: "component---src-pages-homepage-second-slider-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-materiais-area-list-js": () => import("./../src/pages/materiais/AreaList.js" /* webpackChunkName: "component---src-pages-materiais-area-list-js" */),
  "component---src-pages-materiais-js": () => import("./../src/pages/materiais.js" /* webpackChunkName: "component---src-pages-materiais-js" */),
  "component---src-pages-materiais-tabs-component-js": () => import("./../src/pages/materiais/TabsComponent.js" /* webpackChunkName: "component---src-pages-materiais-tabs-component-js" */),
  "component---src-pages-produtos-js": () => import("./../src/pages/produtos.js" /* webpackChunkName: "component---src-pages-produtos-js" */),
  "component---src-pages-produtos-orcamento-box-js": () => import("./../src/pages/produtos/OrcamentoBox.js" /* webpackChunkName: "component---src-pages-produtos-orcamento-box-js" */),
  "component---src-pages-produtos-orcamento-modal-js": () => import("./../src/pages/produtos/OrcamentoModal.js" /* webpackChunkName: "component---src-pages-produtos-orcamento-modal-js" */),
  "component---src-pages-produtos-product-detail-js": () => import("./../src/pages/produtos/ProductDetail.js" /* webpackChunkName: "component---src-pages-produtos-product-detail-js" */),
  "component---src-pages-produtos-product-group-js": () => import("./../src/pages/produtos/ProductGroup.js" /* webpackChunkName: "component---src-pages-produtos-product-group-js" */),
  "component---src-pages-produtos-side-menu-js": () => import("./../src/pages/produtos/SideMenu.js" /* webpackChunkName: "component---src-pages-produtos-side-menu-js" */),
  "component---src-pages-produtos-tool-bar-js": () => import("./../src/pages/produtos/ToolBar.js" /* webpackChunkName: "component---src-pages-produtos-tool-bar-js" */),
  "component---src-pages-sobre-first-slider-js": () => import("./../src/pages/sobre/FirstSlider.js" /* webpackChunkName: "component---src-pages-sobre-first-slider-js" */),
  "component---src-pages-sobre-grid-js": () => import("./../src/pages/sobre/Grid.js" /* webpackChunkName: "component---src-pages-sobre-grid-js" */),
  "component---src-pages-sobre-js": () => import("./../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-somos-mais-js": () => import("./../src/pages/somos-mais.js" /* webpackChunkName: "component---src-pages-somos-mais-js" */),
  "component---src-pages-sustentabilidade-js": () => import("./../src/pages/sustentabilidade.js" /* webpackChunkName: "component---src-pages-sustentabilidade-js" */),
  "component---src-pages-tecnologia-comps-first-grid-js": () => import("./../src/pages/tecnologia/comps/FirstGrid.js" /* webpackChunkName: "component---src-pages-tecnologia-comps-first-grid-js" */),
  "component---src-pages-tecnologia-comps-second-grid-js": () => import("./../src/pages/tecnologia/comps/SecondGrid.js" /* webpackChunkName: "component---src-pages-tecnologia-comps-second-grid-js" */),
  "component---src-pages-tecnologia-comps-sub-page-js": () => import("./../src/pages/tecnologia/comps/SubPage.js" /* webpackChunkName: "component---src-pages-tecnologia-comps-sub-page-js" */),
  "component---src-pages-tecnologia-comps-tabs-component-js": () => import("./../src/pages/tecnologia/comps/TabsComponent.js" /* webpackChunkName: "component---src-pages-tecnologia-comps-tabs-component-js" */),
  "component---src-pages-tecnologia-corte-js": () => import("./../src/pages/tecnologia/corte.js" /* webpackChunkName: "component---src-pages-tecnologia-corte-js" */),
  "component---src-pages-tecnologia-dobra-js": () => import("./../src/pages/tecnologia/dobra.js" /* webpackChunkName: "component---src-pages-tecnologia-dobra-js" */),
  "component---src-pages-tecnologia-engenharia-js": () => import("./../src/pages/tecnologia/engenharia.js" /* webpackChunkName: "component---src-pages-tecnologia-engenharia-js" */),
  "component---src-pages-tecnologia-estamparia-js": () => import("./../src/pages/tecnologia/estamparia.js" /* webpackChunkName: "component---src-pages-tecnologia-estamparia-js" */),
  "component---src-pages-tecnologia-js": () => import("./../src/pages/tecnologia.js" /* webpackChunkName: "component---src-pages-tecnologia-js" */),
  "component---src-pages-tecnologia-pintura-js": () => import("./../src/pages/tecnologia/pintura.js" /* webpackChunkName: "component---src-pages-tecnologia-pintura-js" */),
  "component---src-pages-tecnologia-solda-js": () => import("./../src/pages/tecnologia/solda.js" /* webpackChunkName: "component---src-pages-tecnologia-solda-js" */),
  "component---src-pages-tecnologia-usinagem-js": () => import("./../src/pages/tecnologia/usinagem.js" /* webpackChunkName: "component---src-pages-tecnologia-usinagem-js" */),
  "component---src-pages-trabalhe-conosco-js": () => import("./../src/pages/trabalhe-conosco.js" /* webpackChunkName: "component---src-pages-trabalhe-conosco-js" */),
  "component---src-pages-trabalhe-conosco-main-content-js": () => import("./../src/pages/trabalhe-conosco/MainContent.js" /* webpackChunkName: "component---src-pages-trabalhe-conosco-main-content-js" */),
  "component---src-pages-trabalhe-conosco-side-content-js": () => import("./../src/pages/trabalhe-conosco/SideContent.js" /* webpackChunkName: "component---src-pages-trabalhe-conosco-side-content-js" */),
  "component---src-pages-transporte-js": () => import("./../src/pages/transporte.js" /* webpackChunkName: "component---src-pages-transporte-js" */)
}

